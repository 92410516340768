import { createAction, props } from "@ngrx/store";
import { Alert } from "../state/alert.state";

export const activateAlert = createAction(
    '[Alert] Activate Alert',
    props<{ alert: Alert }>()
);

export const deactivateAlert = createAction(
    '[Alert] Deactivate Alert'
);
